<template>

  <FullScreenHeader />

  <ShowCase :skills="skills" />

  <SkillsDisplay />

  <section id="features" class="animation-slide-locks container-fluid pt-lg-4 pt-xl-5 pb-5 pb-xl-5 mt-n2 mt-sm-0 mb-2 mb-md-4 mb-lg-5">
    
    <div class="container row pb-xl-3">

      <!-- Parallax gfx -->
      <div class="col-lg-5 d-none d-lg-block justify-content-center align-center">
        <div class="position-relative" style="max-width: 448px;">
          <img src="@/assets/img/landing/app-showcase-1/features/phone_sm.webp" width="450" height="750" alt="Phone">
        </div>
      </div>

      <!-- Feature list -->
      <div class="col-lg-7">
        <div class="card bg-transparent mt-sm-5" style="backdrop-filter: blur(2px);">
          <div class="card-body">
            <h2 class="h1 mb-4">
              <div class="d-flex align-items-start">
                <div class="rounded-3 p-1 h4" style="background: #1A1A1A;">
                  <img src="@/assets/img/landing/app-showcase-1/icon.webp" class="img" width="54" height="54" alt="P455w0rd Icon" />
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  P455w0rd
                </div>
              </div>
            </h2>
            <p class="fs-lg text-center text-md-start mb-4 mb-xl-5">Empower your online security with our Password Manager App, designed to effortlessly safeguard and recall all your passwords and critical information with ease.</p>
          

          <div class="row row-cols-1 row-cols-sm-2 pt-2 pt-sm-3 pt-xl-2">
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start pe-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 mb-3 h4">
                  <!-- <img src="@/assets/svg/icons/payments.svg" alt="Icon"> -->
                   <i class="bx bx-lock mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Master Password</h3>
                  <p class="mb-0">Single password to access all stored credentials.</p>
                </div>
              </div>
            </div>
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start ps-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 mb-3 h4">
                  <!-- <img src="@/assets/svg/icons/security.svg" alt="Icon"> -->
                  <i class="bx bx-lock-alt mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Secure Vault</h3>
                  <p class="mb-0">Encrypts and stores passowrds.</p>
                </div>
              </div>
            </div>
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start pe-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 h4 mb-3">
                  <!-- <img src="@/assets/svg/icons/statistics.svg" alt="Icon"> -->
                  <i class="bx bx-wifi-off mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Offline Mode</h3>
                  <p class="mb-0">Access stored credentials without Internet access.</p>
                </div>
              </div>
            </div>
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start ps-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 h4 mb-3">
                  <!-- <img src="@/assets/svg/icons/support.svg" alt="Icon"> -->
                  <i class="bx bx-folder mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Custom Categories</h3>
                  <p class="mb-0">Organize credentials efficiently with folders.</p>
                </div>
              </div>
            </div>
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start pe-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 h4 mb-3">
                  <!-- <img src="@/assets/svg/icons/cashback.svg" alt="Icon"> -->
                  <i class="bx bx-notepad mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Secure Notes</h3>
                  <p class="mb-0">Store sensitive text data (e.g., recovery codes, PINs).</p>
                </div>
              </div>
            </div>
  
            <!-- Item -->
            <div class="col pb-2 pb-xl-0 mb-4 mb-xl-5">
              <div class="d-flex align-items-start ps-xl-3">
                <div class="d-table bg-secondary rounded-3 flex-shrink-0 p-1 h4 mb-3">
                  <!-- <img src="@/assets/svg/icons/happy.svg" alt="Icon"> -->
                  <i class="bx bx-check-shield mx-1 text-gradient-primary"></i>
                </div>
                <div class="ps-4 ps-sm-3 ps-md-4">
                  <h3 class="h5 pb-1 mb-2">Password Health Check</h3>
                  <p class="mb-0">Identifies weak, reused, or compromised passwords.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        </div>
        

        
      </div>
    </div>
  </section>

  <section v-if="1 == 2" class="app-showcase container-fluid bg-secondary m-0">
    <div class="item-row">
      <div class="item">
        <img src="http://a1.mzstatic.com/us/r30/Purple71/v4/b3/f4/26/b3f4264b-ec2e-39d0-ea50-1cdfca64cf82/sc1024x768.jpeg" alt="">
      </div>
      <div class="item">
        <img src="@/assets/img/games/2048.png" alt="">
      </div>
      <div class="item">
        <img src="http://a1.mzstatic.com/us/r30/Purple71/v4/b3/f4/26/b3f4264b-ec2e-39d0-ea50-1cdfca64cf82/sc1024x768.jpeg" alt="">
      </div>
      <div class="item">
        <img src="@/assets/img/games/2048.png" alt="">
      </div>
      <div class="item">
        <img src="http://a1.mzstatic.com/us/r30/Purple71/v4/b3/f4/26/b3f4264b-ec2e-39d0-ea50-1cdfca64cf82/sc1024x768.jpeg" alt="">
      </div>
      <div class="item">
        <img src="@/assets/img/games/2048.png" alt="">
      </div>
    </div>
  </section>


          
  <div class="container px-xxl-5 px-lg-4 pt-4 pt-lg-5 pb-5">
      <!-- Page title -->
      <h1 class="ps-lg-2 ps-xxl-0 mt-2 mt-lg-0 pt-4 pb-2 mb-3 mb-xl-4">{{ pageTitle }}</h1>

      <!-- Welcome message -->
      <div class="alert alert-success d-flex fs-md mb-4" role="alert">
        <i class="bx bx-smile lead me-2 me-sm-3"></i>
        <div><strong>Welcome!</strong> {{ welcomeMessage }}</div>
      </div>

      <!-- Info alert -->
      <div class="alert alert-info d-flex fs-md mb-4" role="alert">
        <i class="bx bx-info-circle lead me-2 me-sm-3"></i>
        <div><strong>Please note:</strong> {{ alertMessage }}</div>
      </div>

      <!-- Section title -->
      <h2 class="h4 pb-1 pt-3 pt-xl-4">{{ sectionTitle }}</h2>
      <div class="my-4">
        <dl>
          <dt class="mb-1">Cool Sites with Bookmarks</dt>
          <dd class="fs-sm pb-3 border-bottom">
            A collection of useful and interesting web applications that can enhance productivity and creativity. Check out these bookmarks to discover new tools and resources!
            <ul>
              <li><a href="https://www.producthunt.com/" target="_blank" rel="noopener">Product Hunt</a> - Discover the latest in tech and apps.</li>
              <li><a href="https://www.later.com/" target="_blank" rel="noopener">Later</a> - Schedule and manage your social media posts.</li>
              <li><a href="https://www.canva.com/" target="_blank" rel="noopener">Canva</a> - Create stunning graphics and designs effortlessly.</li>
              <li><a href="https://alternativeto.net/" target="_blank" rel="noopener">AlternativeTo</a> - Find alternatives to your favorite software and web apps based on user recommendations.</li>
              <li><a href="https://archive.org/" target="_blank" rel="noopener">Internet Archive</a> - A digital library offering free universal access to books, movies, music, and more.</li>

            </ul>
            <div class="text-end">

              <a href="#" class="btn-link">More</a>
            </div>
          </dd>

          <dt class="mb-1">Cryptography Tools</dt>
          <dd class="fs-sm pb-3 border-bottom">
            Explore the fascinating world of cryptography! This section provides useful cryptographic tools, along with explanations of their functions and the history behind cryptographic practices.
            <ul>
              <li><a href="https://www.gpg4win.org/" target="_blank" rel="noopener">Gpg4win</a> - A Windows package for email and file encryption.</li>
              <li><a href="https://cryptii.com/" target="_blank" rel="noopener">Cryptii</a> - A web-based tool for encoding and decoding various formats.</li>
              <li><a href="https://www.cryptography.io/" target="_blank" rel="noopener">Cryptography.io</a> - Learn about the principles of cryptography, algorithms, and more!</li>
            </ul>
          </dd>
        </dl>
      </div>
      <p class="text-dark mb-4"><u>{{ additionalInfo }}</u></p>
  </div>

  
</template>

<script>
import FullScreenHeader from '@/components/FullScreenHeader.vue';
import ShowCase from '@/components/ShowCase.vue'
import SkillsDisplay from '@/components/SkillsDisplay.vue'

export default {
  data() {
    return {
      pageTitle: "Welcome to My Resource Hub",
      welcomeMessage: "This platform is designed to be a handy collection of useful resources—whether you're looking for documentation, downloadable files such as books or source code, or just want to explore cool and informative links. Feel free to explore and make the most of the resources available here.",
      alertMessage: "This page is constantly updated. Some resources are external, while others are hosted right here for your convenience.",
      sectionTitle: "Highlighted Resources",
      additionalInfo: "Explore the curated collection of development tools, educational materials, and useful documentation designed to support your projects.",
      
    };
  },
  components: {
    FullScreenHeader,
    ShowCase,
    SkillsDisplay,
},

};
</script>

<style scoped>

@keyframes slideBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -370px 0;
  }
}

.animation-slide-locks {
  background: var(--si-body-bg) url('@/assets/img/landing/cover.webp') repeat center/380px;
  animation: slideBackground 34s linear infinite;
}


.app-showcase {
  /* clip-path: inset(0 0 0 0); */
  overflow: hidden;
}
.app-showcase .item-row {
  height: 44vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}

.app-showcase .item-row .item {
  /* position: relative; */

  /* width: 20vw; */
  width: calc(100vh / 5);
  height: 30vh;

  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  transition: scale 0.75s, clip-path 0.5s, filter 0.25s;
  margin: -2.85vh;
  /* margin: 0; */
}

.app-showcase .item-row .item:hover {
  position: relative;
  clip-path: none;
  scale: 1.2;
  z-index: 100;
}
.app-showcase .item-row .item:hover img {
  /* width: 100%;
  height: 100%;
  object-fit: contain; */
  filter: drop-shadow(4px 4px 5px rgba(0,0,0,0.2));
  /* border-radius: 12px; */
}
.app-showcase .item-row .item img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
}

/* .app-showcase:has(> .item:hover) {
  clip-path: none;
} */


</style>
