import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import UIKitLayout from '@/layouts/UIKitLayout.vue'
import BlogLayout from '@/layouts/BlogLayout.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          breadcrumb: 'Home',
          title: 'Home - d1m Hub',
          metaTags: [
            {
              name: 'd1m4.xyz - Hub for small projects, games, apps and tools',
              description: 'Explore creative digital solutions, discover useful resources, and experiment with innovative applications designed for all users.',
            }
          ]
        },
        // meta: {
        //   breadcrumb: 'Home',
        // },
        component: HomeView
      },

      {
        path: '/bookmarks',
        name: 'Cool websites',
        meta: {
          breadcrumb: 'Home',
          title: 'Cool sites - d1m Hub',
          metaTags: [
            {
              name: 'A collection of powerful or interesting websites.',
            }
          ]
        },
        component: () => import('../views/BookmarksView.vue'),
      },
      {
        path: '/great-minds',
        name: 'Great Minds',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GreatMinds.vue'),
      },
      {
        path: '/quotes',
        name: 'Quotes',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/QuotesPage.vue'),
      },
      {
        path: '/ads/graphs',
        name: 'Graphs and Trees',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GraphsLesson.vue'),
      },
      {
        path: '/graphs',
        name: 'Graphs',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GraphPage.vue'),
      },
      {
        path: '/adt-time-complexity',
        name: 'Abstract Data Types Time Complexity',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/AdtTimeComplexityView.vue'),
      },

      


      {
        path: '/saving-calculator',
        name: 'Saving Calculator',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Saving account calculator - d1m Hub',
        },
        component: () => import('../views/CalculateInterest.vue'),
      },
      {
        path: '/human-history',
        name: 'Human History',
        meta: {
          breadcrumb: 'Concepts',
          title: 'History of humankind - d1m Hub',
        },
        component: () => import('../views/HumanHistoryView.vue'),
      },



      {
        path: '/phranks',
        name: 'PhrankView',
        meta: {
          breadcrumb: 'Faker',
          title: 'Phrank View - d1m Hub',
          // requiresAuth: true,
        },
        component: () => import('../views/PhrankView.vue'),
      },
      {
        path: '/matrix-screen',
        name: 'MatrixView',
        meta: {
          breadcrumb: 'Faker',
          title: 'Matrix View - d1m Hub',
          // requiresAuth: true,
        },
        component: () => import('../views/MatrixView.vue'),
      },
      {
        path: '/windows-blue-death',
        name: 'Windows blue death screen',
        meta: {
          breadcrumb: 'Faker',
          title: 'Windows Blue Screen of Death - d1m Hub',
          // requiresAuth: true,
        },
        component: () => import('../views/WindowsDeathScreen.vue'),
      },
      {
        path: '/windows-update',
        name: 'Windows update',
        meta: {
          breadcrumb: 'Faker',
          title: 'Windows Update - d1m Hub',
          // requiresAuth: true,
        },
        component: () => import('../views/WindowsUpdateView.vue'),
      },
      {
        path: '/chat-ui',
        name: 'Chat Interface',
        meta: {
          breadcrumb: 'Ui',
          title: 'Chat interface - d1m Hub',
          requiresAuth: true,
        },
        component: () => import('../views/ui_kit/ChatInterfaceView.vue'),
      },


      // Cryptograpy Tools
      {
        path: '/xor-cipher',
        name: 'Xork Cipher',
        meta: {
          breadcrumb: 'Concepts',
          title: 'XOR Cipher Encrypt and Decrypt - d1m Hub',
        },
        component: () => import('../views/XorChipher.vue'),
      },
      {
        path: '/caesar-cipher',
        name: 'Caesar Cipher',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Caesar Cipher Encrypt and Decrypt - d1m Hub',
        },
        component: () => import('../views/CaesarCipherView.vue'),
      },
      {
        path: '/expiry-message',
        name: 'ExpiryMessage',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Self destruct & password protected Message - d1m Hub',
        },
        component: () => import('../views/ExpiryMessage.vue'),
      },
      {
        path: '/expiry-message/:message_id',
        name: 'ExpiryMessageView',
        props: true,
        meta: {
          breadcrumb: 'Concepts',
          title: 'Self destruct & password protected Message - d1m Hub',
        },
        component: () => import('../views/ExpiryMessage.vue'),
      },
      // {
      //   path: '/expiry-message/:id',
      //   name: 'ExpiryMessageView',
      //   props: true,
      //   meta: {
      //     breadcrumb: 'Concepts',
      //     title: 'Self destruct & password protected Message View - d1m Hub',
      //   },
      //   component: () => import('../views/ExpiryMessageView.vue'),
      // },


      {
        path: '/binary-search-tree',
        name: 'Binary Search Tree Vizualization',
        meta: {
          breadcrumb: 'Concepts',
          title: 'cross the river game - d1m Hub',
        },
        component: () => import('../views/BinarySearchTree.vue'),
      },
      {
        path: '/sorting-algorithms',
        name: 'Sorting Algorithms Vizualization',
        meta: {
          breadcrumb: 'Concepts',
          title: 'Sorting Algorithms Vizualization - d1m Hub',
        },
        component: () => import('../views/SortingAlgorithms.vue'),
      },

      {
        path: '/window-designer',
        name: 'Window Designer',
        meta: {
          breadcrumb: 'Home',
          title: 'Window Designer - d1m Hub',
        },
        component: () => import('../views/WindowDesigner.vue'),
      },


      {
        path: '/particle-sphere',
        name: 'Particle Sphere',
        meta: {
          breadcrumb: 'Home',
          title: 'Particle Sphere - d1m Hub',
        },
        component: () => import('../views/ParticleSphere.vue'),
      },

      {
        path: '/hourglass',
        name: 'Hourglass',
        meta: {
          breadcrumb: 'Tools',
          title: 'Hourglass - d1m Hub',
        },
        component: () => import('../views/HourGlassView.vue'),
      },
      {
        path: '/bmi-calculator',
        name: 'BMI Calculator',
        meta: {
          breadcrumb: 'Tools',
          title: 'Body Mass Index Calculator - d1m Hub',
        },
        component: () => import('../views/BMICalculator.vue'),
      },

      {
        path: '/binary-operations',
        name: 'Binary Operations',
        meta: {
          breadcrumb: 'Tools',
          title: 'Flash Caeds - d1m Hub',
        },
        component: () => import('../views/BinaryOperationsView.vue'),
      },




      {
        path: '/solar-system',
        name: 'Solar System',
        meta: {
          breadcrumb: 'Home',
          title: 'Solar system - d1m Hub',
        },
        component: () => import('../views/SolarSystem.vue'),
      },
      {
        path: '/scroll-to-the-abyss',
        name: 'Scroll to the abyss',
        meta: {
          breadcrumb: 'Home',
          title: 'Scroll to the abyss - d1m Hub',
        },
        component: () => import('../views/ScrollToAbyss.vue'),
      },

      {
        path: '/sleep-calculator',
        name: 'Sleep Calculator',
        meta: {
          breadcrumb: 'Home',
          title: 'Sleep calculator - d1m Hub',
        },
        component: () => import('../views/SleepCalculator.vue'),
      },

      {
        path: '/rubix-cube-algorithms',
        name: 'rubix-cube-algorithms',
        component: () => import('../views/RubixCubeAlgorithmsView.vue'),
      },
      {
        path: '/rubix-cube-stopwatch',
        name: 'Speed Cubing Stopwatch',
        meta: {
          breadcrumb: 'Games',
          title: 'Speed Cubing Stopwatch - d1m Hub',
        },
        component: () => import('../views/games/SpeedCubingStopwatch.vue'),
      },
      {
        path: '/flash-cards',
        name: 'Flash Cards',
        meta: {
          breadcrumb: 'Home',
          title: 'Flash Caeds - d1m Hub',
        },
        component: () => import('../views/QuestionsView.vue'),
      },
      {
        path: '/keyboard',
        name: 'Keyboard',
        meta: {
          breadcrumb: 'Home',
          title: 'Keyboard button tester - d1m Hub',
        },
        component: () => import('../views/KeyboardView.vue'),
      },
      {
        path: '/audio-vizualizer',
        name: 'AudioVizualizer',
        meta: {
          breadcrumb: 'Home',
          title: 'Audio and Microphone tester - d1m Hub',
        },
        component: () => import('../views/AudioVizualizer.vue'),
      },

      

      // Developer tools
      {
        path: '/developer-checklist',
        name: 'Developer Checklist',
        meta: {
          breadcrumb: 'Home',
          title: 'Developer Checklist - d1m Hub',
        },
        component: () => import('../views/DeveloperChecklist.vue'),
      },
      
      {
        path: '/subnet-calculator',
        name: 'SubnetCalculator',
        meta: {
          breadcrumb: 'Home',
          title: 'IP Subnet Calculator - d1m Hub',
        },
        component: () => import('../views/SubnetCalculator.vue'),
      },
      {
        path: '/minifier',
        name: 'Minifier',
        meta: {
          breadcrumb: 'Home',
          title: 'Minifier - d1m Hub',
        },
        component: () => import('../views/MinifierView.vue'),
      },
      
      {
        path: '/css-generator',
        name: 'CSS generator',
        meta: {
          title: 'CSS - d1m Hub',
        },
        component: () => import('../views/CssGeneratorView.vue'),
      },


      {
        path: '/css-backgrounds',
        name: 'Css Backgrounds',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/TexturesGrid.vue'),
      },

      {
        path: '/css-gradient',
        name: 'Css Gradient',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/CssGradientView.vue'),
      },
      {
        path: '/color/:color?',
        name: 'HexColorView',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/RgbColorView.vue'),
      },
      {
        path: '/get-sprite-xy',
        name: 'Get Sprite XY',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/GetSpriteXY.vue'),
      },
      {
        path: '/img-to-base64',
        name: 'Image to base64',
        meta: {
          breadcrumb: 'Home',
          title: 'Image to base64 - d1m Hub',
        },
        component: () => import('../views/ImgToBase64View.vue'),
      },





      // Cheatsheets
      {
        path: '/http-status-codes',
        name: 'HTTPStatusCodes',
        meta: {
          breadcrumb: 'Home',
          title: 'http Status Codes explained - d1m Hub',
        },
        component: () => import('../views/HttpStatusCodes.vue'),
      },
      {
        path: '/bootstrap-cheatsheet',
        name: 'Bootstrap Cheatsheet',
        meta: {
          breadcrumb: 'Home',
          title: 'Bootstrap Cheatsheet - d1m Hub',
        },
        component: () => import('../views/BootstrapCheatsheet.vue'),
      },
      {
        path: '/tmux-cheatsheet',
        name: 'TMux Cheatsheet',
        meta: {
          breadcrumb: 'Home',
          title: 'TMux Cheatsheet - d1m Hub',
        },
        component: () => import('../views/TMuxCheatsheet.vue'),
      },
      {
        path: '/nginx-cheatsheet',
        name: 'nginx Cheatsheet',
        meta: {
          breadcrumb: 'Home',
          title: 'nginx Cheatsheet - d1m Hub',
        },
        component: () => import('../views/NginxCheatsheet.vue'),
      },
      {
        path: '/linux-commands',
        name: 'Linux Bash Commands',
        meta: {
          breadcrumb: 'Home',
          title: 'Linux Bash Commands - d1m Hub',
        },
        component: () => import('../views/LinuxComandsView.vue'),
      },


      // Games
      {
        path: '/rps/create',
        name: 'create-session',
        component: () => import('../views/games/RPSCreateSession.vue'),
      },
      {
        path: '/rps/game/:sessionId',
        name: 'game',
        component: () => import('../views/games/RockPaperScissors.vue'),
      },
      
      {
        path: '/games',
        name: 'Games main',
        meta: {
          breadcrumb: 'Games',
          title: 'Games main - d1m Hub',
        },
        component: () => import('../views/games/GamesMainView.vue'),
      },

      {
        path: '/games/language-game',
        name: 'Language Game',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/LanguageGame.vue'),
      },

      {
        path: '/games/world-map',
        name: 'World Map',
        meta: {
          breadcrumb: 'Home',
        },
        component: () => import('../views/WorldMapView.vue'),
      },
      {
        path: '/games/2048',
        name: '2048',
        meta: {
          breadcrumb: 'Games',
          title: '2048 - d1m Hub',
        },
        component: () => import('../views/games/TwoKFortyEight.vue'),
      },
      {
        path: '/games/flappy-bird',
        name: 'Flappy bird',
        meta: {
          breadcrumb: 'Games',
          title: 'Flappy Bird - d1m Hub',
        },
        component: () => import('../views/games/FlappyBird.vue'),
      },
      {
        path: '/games/doodle-jump',
        name: 'Doodle Jump',
        meta: {
          breadcrumb: 'Games',
          title: 'Doodle Jump - d1m Hub',
        },
        component: () => import('../views/games/DoodleJump.vue'),
      },

      {
        path: '/games/jigsaw-puzzle',
        name: 'Jigsaw Puzzle',
        meta: {
          breadcrumb: 'Games',
          title: 'Jigsaw puzzle - d1m Hub',
        },
        component: () => import('../views/games/JigsawPuzzle.vue'),
      },
      {
        path: '/games/aim-trainer',
        name: 'Aim Trainer',
        meta: {
          breadcrumb: 'Games',
          title: 'Aim Trainer - d1m Hub',
        },
        component: () => import('../views/games/AimTrainerGame.vue'),
      },
      {
        path: '/games/reaction-time',
        name: 'Reaction Time',
        meta: {
          breadcrumb: 'Games',
          title: 'Reaction Time - d1m Hub',
        },
        component: () => import('../views/games/ReactionTimeGame.vue'),
      },
      {
        path: '/games/speed-typing',
        name: 'Speed typing',
        meta: {
          breadcrumb: 'Games',
          title: 'Speed Typing - d1m Hub',
        },
        component: () => import('../views/games/SpeedTyping.vue'),
      },
      {
        path: '/games/imune-system',
        name: 'Imune System',
        meta: {
          breadcrumb: 'Games',
          title: 'Imune System - d1m Hub',
        },
        component: () => import('../views/games/ImuneSystem.vue'),
      },
      {
        path: '/games/sequence-memory',
        name: 'Sequence Memory',
        meta: {
          breadcrumb: 'Games',
          title: 'Sequence Memory - d1m Hub',
        },
        component: () => import('../views/games/SequenceMemory.vue'),
      },
      {
        path: '/games/crack-the-code',
        name: 'Crack the code',
        meta: {
          breadcrumb: 'Games',
          title: 'Crack the code - d1m Hub',
        },
        component: () => import('../views/games/CrackTheCode.vue'),
      },
      {
        path: '/games/sudoku',
        name: 'Sudoku',
        meta: {
          breadcrumb: 'Games',
          title: 'Classic Sudoku - d1m Hub',
        },
        component: () => import('../views/games/SudokuGame.vue'),
      },
      {
        path: '/games/thief',
        name: 'Thief Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Catch the thief game - d1m Hub',
        },
        component: () => import('../views/games/ThiefGame.vue'),
      },
      {
        path: '/games/cabbage-sheep-wolf',
        name: 'Cabbage sheep wolf Game',
        meta: {
          breadcrumb: 'Games',
          title: 'cross the river game - d1m Hub',
        },
        component: () => import('../views/games/SheepGame.vue'),
      },
      {
        path: '/games/roulette',
        name: 'Roulette Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Try win the casino roulette - d1m Hub',
        },
        component: () => import('../views/games/RouletteGame.vue'),
      },
      {
        path: '/games/black-jack',
        name: 'Blackjack Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Black jack game - d1m Hub',
        },
        component: () => import('../views/games/BlackjackGame.vue'),
      },
      {
        path: '/games/slot-machine',
        name: 'Slot Machine',
        meta: {
          breadcrumb: 'Games',
          title: 'Slot Machine - d1m Hub',
        },
        component: () => import('../views/games/SlotMachineGame.vue'),
      },
      {
        path: '/games/memory-game',
        name: 'Memory Game',
        meta: {
          breadcrumb: 'Games',
          title: 'Memory Game - d1m Hub',
        },
        component: () => import('../views/games/MemoryGame.vue'),
      },



      // Static Pages ish
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicyView',
        meta: {
          breadcrumb: 'Home',
          title: 'Privacy Policy - d1m Hub',
          metaTags: [
            {
              name: 'A collection of powerful or interesting websites.',
            }
          ]
        },
        component: () => import('../views/PrivacyPolicyView.vue'),
      },
      {
        path: '/changelog',
        name: 'Changelog',
        meta: {
          title: 'Changelog - d1m Hub',
        },
        component: () => import('../views/ChangelogView.vue'),
      },

      // Projects

      {
        path: '/projects',
        name: 'Projects',
        meta: {
          title: 'Projects - d1m Hub',
        },
        component: () => import('../views/ProjectsView.vue'),
      },

      // Blog
      {
        path: '/blog',
        component: BlogLayout,
        children: [
          {
            path: '',
            name: 'Blog Home',
            meta: {
              breadcrumb: 'Blog Home',
            },
            component: () => import('../views/blog/BlogHome.vue'),
          },
          {
            path: 'add',
            name: 'Add Blog',
            meta: {
              breadcrumb: 'Blog',
            },
            component: () => import('../views/blog/AddBlog.vue'),
          },
          {
            path: ":id",
            name: "BlogDetail",
            component: () => import('../views/blog/BlogDetailView.vue'),
            props: true,
          },       
          {
            path: "category/:categoryName",
            name: "CategoryBlogs",
            component: () => import('../views/blog/BlogCategoryList.vue'),
            props: true,
          },
          {
            path: '/blog/tag/:tagName',
            name: 'TagBlogs',
            component: () => import('../views/blog/BlogTagsList.vue'),  // Lazy loading
            props: true,
          },
          

          //blog manager
          {
            path: ':id/edit',
            name: 'Update Blog',
            meta: {
              breadcrumb: 'Blog',
            },
            component: () => import('../views/blog/AddBlog.vue'),
          },
          {
            path: 'manager/categories',
            name: 'Category Manager | Blog',
            meta: {
              breadcrumb: 'Blog',
            },
            component: () => import('../views/blog/CategoryManager.vue'),
          },
          {
            path: 'manager/tags',
            name: 'Tags Manager | Blog',
            meta: {
              breadcrumb: 'Blog',
            },
            component: () => import('../views/blog/TagsManager.vue'),
          },
          {
            path: 'manager/posts',
            name: 'Blog Post Manager | Blog',
            meta: {
              breadcrumb: 'Blog',
            },
            component: () => import('../views/blog/BlogPostManager.vue'),
          },
        ],
      },
    ]
  },
  

  //Authentication
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/auth/LoginView.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: () => import('../views/auth/ForgotPasswordView.vue')
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('../views/auth/RegisterView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/auth/ProfileView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/auth/UserSettingsView.vue'),
    meta: { requiresAuth: true },
  },
  


  // Ui kit
  {
    path: '/ui_kit/',
    component: UIKitLayout,
    children: [
      {
        path: 'typography',
        name: 'TypographyView',
        component: () => import('../views/ui_kit/TypographyView.vue')
      },
      {
        path: 'icon-font',
        name: 'IconFontView',
        component: () => import('../views/ui_kit/IconFontView.vue')
      },
      {
        path: 'code',
        name: 'CodeView',
        component: () => import('../views/ui_kit/CodeView.vue')
      },
      {
        path: 'images',
        name: 'ImagesView',
        component: () => import('../views/ui_kit/ImagesView.vue')
      },
      {
        path: 'tables',
        name: 'TablesView',
        component: () => import('../views/ui_kit/TablesView.vue')
      },
      {
        path: 'accordion',
        name: 'AccordionView',
        component: () => import('../views/ui_kit/AccordionView.vue')
      },
      {
        path: 'audio-player',
        name: 'AudioPlayerView',
        component: () => import('../views/ui_kit/AudioPlayerView.vue')
      },
      {
        path: 'alerts',
        name: 'AlertsView',
        component: () => import('../views/ui_kit/AlertsView.vue')
      },
      {
        path: 'badges',
        name: 'BadgesView',
        component: () => import('../views/ui_kit/BadgesView.vue')
      },
      {
        path: 'blog-components',
        name: 'BlogComponentsView',
        component: () => import('../views/ui_kit/BlogComponentsView.vue')
      },
      {
        path: 'breadcrumbs',
        name: 'BreadcrumbsView',
        component: () => import('../views/ui_kit/BreadcrumbsView.vue')
      },
      {
        path: 'buttons',
        name: 'ButtonsView',
        component: () => import('../views/ui_kit/ButtonsView.vue')
      },
      {
        path: 'button-group',
        name: 'ButtonGroupView',
        component: () => import('../views/ui_kit/ButtonGroupView.vue')
      },
      {
        path: 'cards',
        name: 'CardsBasicView',
        component: () => import('../views/ui_kit/CardsBasicView.vue')
      },
      {
        path: 'cards-hover',
        name: 'CardsHoverView',
        component: () => import('../views/ui_kit/CardsHoverView.vue')
      },
      {
        path: 'carousel',
        name: 'CarouselView',
        component: () => import('../views/ui_kit/CarouselView.vue')
      },
      {
        path: 'charts',
        name: 'ChartsView',
        component: () => import('../views/ui_kit/ChartsView.vue')
      },
      {
        path: 'collapse',
        name: 'CollapseView',
        component: () => import('../views/ui_kit/CollapseView.vue')
      },
      {
        path: 'countdown',
        name: 'CountdownView',
        component: () => import('../views/ui_kit/CountdownView.vue')
      },
      {
        path: 'date-picker',
        name: 'DatepickerView',
        component: () => import('../views/ui_kit/DatepickerView.vue')
      },
      {
        path: 'dropdowns',
        name: 'DropdownsView',
        component: () => import('../views/ui_kit/DropdownsView.vue')
      },
      {
        path: 'event-calendar',
        name: 'EventCalendarView',
        component: () => import('../views/ui_kit/EventCalendarView.vue')
      },
      {
        path: 'forms',
        name: 'FormsView',
        component: () => import('../views/ui_kit/FormsView.vue')
      },
      {
        path: 'gallery',
        name: 'GalleryView',
        component: () => import('../views/ui_kit/GalleryView.vue')
      },
      {
        path: 'hotspots',
        name: 'HotspotsView',
        component: () => import('../views/ui_kit/HotspotsView.vue')
      },
      {
        path: 'image-comparison-slider',
        name: 'ImageComparisonSliderView',
        component: () => import('../views/ui_kit/ImageComparisonSliderView.vue')
      },
      {
        path: 'input-group',
        name: 'InputGroupView',
        component: () => import('../views/ui_kit/InputGroupView.vue')
      },
      {
        path: 'list-group',
        name: 'ListGroupView',
        component: () => import('../views/ui_kit/ListGroupView.vue')
      },
      {
        path: 'maps',
        name: 'MapsView',
        component: () => import('../views/ui_kit/MapsView.vue')
      },
      {
        path: 'modal',
        name: 'ModalView',
        component: () => import('../views/ui_kit/ModalView.vue')
      },
      {
        path: 'navbar',
        name: 'NavbarView',
        component: () => import('../views/ui_kit/NavbarView.vue')
      },
      {
        path: 'offcanvas',
        name: 'OffcanvasView',
        component: () => import('../views/ui_kit/OffcanvasView.vue')
      },
      {
        path: 'pagination',
        name: 'PaginationView',
        component: () => import('../views/ui_kit/PaginationView.vue')
      },
      {
        path: 'parallax',
        name: 'ParallaxView',
        component: () => import('../views/ui_kit/ParallaxView.vue')
      },
      {
        path: 'pills',
        name: 'PillsView',
        component: () => import('../views/ui_kit/PillsView.vue')
      },
      {
        path: 'placeholders',
        name: 'PlaceholdersView',
        component: () => import('../views/ui_kit/PlaceholdersView.vue')
      },
      {
        path: 'popovers',
        name: 'PopoversView',
        component: () => import('../views/ui_kit/PopoversView.vue')
      },
      {
        path: 'portfolio-components',
        name: 'PortfolioComponentsView',
        component: () => import('../views/ui_kit/PortfolioComponentsView.vue')
      },
      {
        path: 'pricing',
        name: 'PricingView',
        component: () => import('../views/ui_kit/PricingView.vue')
      },
      {
        path: 'progress',
        name: 'ProgressView',
        component: () => import('../views/ui_kit/ProgressView.vue')
      },
      {
        path: 'shop-components',
        name: 'ShopComponentsView',
        component: () => import('../views/ui_kit/ShopComponentsView.vue')
      },
      {
        path: 'social-buttons',
        name: 'SocialButtonsView',
        component: () => import('../views/ui_kit/SocialButtonsView.vue')
      },
      {
        path: 'spinners',
        name: 'SpinnersView',
        component: () => import('../views/ui_kit/SpinnersView.vue')
      },
      {
        path: 'steps',
        name: 'StepsView',
        component: () => import('../views/ui_kit/StepsView.vue')
      },
      {
        path: 'tabs',
        name: 'TabsView',
        component: () => import('../views/ui_kit/TabsView.vue')
      },
      {
        path: 'team',
        name: 'TeamView',
        component: () => import('../views/ui_kit/TeamView.vue')
      },
      {
        path: 'testimonials',
        name: 'TestimonialsView',
        component: () => import('../views/ui_kit/TestimonialsView.vue')
      },
      {
        path: 'toasts',
        name: 'ToastsView',
        component: () => import('../views/ui_kit/ToastsView.vue')
      },
      {
        path: 'tooltips',
        name: 'TooltipsView',
        component: () => import('../views/ui_kit/TooltipsView.vue')
      },
      {
        path: 'video-popup',
        name: 'VideoPopupView',
        component: () => import('../views/ui_kit/VideoPopupView.vue')
      },
      {
        path: 'utilites',
        name: 'UtilitesView',
        component: () => import('../views/ui_kit/UtilitesView.vue')
      },
      //
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/errors/NotFound.vue')
  },
  {
    path: '/*',
    name: 'NotFound 2',
    component: () => import('../views/errors/NotFound.vue')
  },
]







const router = createRouter({
  history: createWebHashHistory(),
  // mode: 'hash',
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active-',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Restore scroll position on back/forward navigation
    }
    if (to.hash) {
      console.log('to hash', to.hash);
      
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },

});


router.beforeEach(async (to, from, next) => {
  // Find the nearest route with a title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // Set the document title
  document.title = nearestWithTitle ? nearestWithTitle.meta.title : 'D1m4\'s website';

  // Manage meta tags efficiently
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags.forEach(tagDef => {
      let tag = document.querySelector(`meta[name="${tagDef.name}"]`);
      if (!tag) {
        tag = document.createElement('meta');
        tag.setAttribute('name', tagDef.name);
        tag.setAttribute('data-vue-router-controlled', '');
        document.head.appendChild(tag);
      }
      tag.setAttribute('content', tagDef.content);
    });
  }

  // Remove stale meta tags
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => {
    if (!nearestWithMeta || !nearestWithMeta.meta.metaTags.find(tag => tag.name === el.getAttribute('name'))) {
      el.parentNode.removeChild(el);
    }
  });

  // Authentication check
  const isAuthenticated = store.getters.isAuthenticated;
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }, // Save intended route
    });
  } else if (isAuthenticated && to.path === '/login') {
    next('/');
  } else {
    next();
  }
});


export default router
